import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { sortSonglist } from './actions'
import IntroDialog from './IntroDialog'
import Help from './Help'
import SongList from './SongList'
import Shortlist from './Shortlist'
import Header from './Header'
import HeaderLite from './HeaderLite'
import HotList from './HotList'

import {
  Switch,
  Route,
} from "react-router-dom";

const Body = (props) => {
  const { dispatch } = props
  useEffect(() => {
    dispatch(sortSonglist('Random', '') )
  }, [dispatch])

  const [ introOpen, setIntroOpen ] = useState(true)

  return (
    <Switch>
      <Route path="/hot/:id">
        <HeaderLite />
        <HotList />
      </Route>
      <Route
        path="/"
        render={({ location }) => (
          < >
             <HeaderLite />
            {/* <Header location={location}/> */}
            <Switch>
							<Route path="/help" render={() => <Help />} />
              <Route path="/shortlist" render={() => <Shortlist />} />
              {/* <Route path="/" render={() => <SongList />} /> */}
            </Switch>
            <IntroDialog open={introOpen} onClose={() => setIntroOpen(false)} />
          </ >
        )}
      />
    </Switch>
  )
}

export default withRouter( connect(  ) (Body))
