import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import { ThemeProvider } from '@material-ui/styles';

import { createMuiTheme } from '@material-ui/core/styles';
import { pink, orange } from '@material-ui/core/colors';

import Clear from '@material-ui/icons/Clear';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import AddIcon from '@material-ui/icons/Add';

const IntroDialog = (props, dispatch) => {
  const { onClose, selectedValue, open } = props;

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#A878F8',
      },
      secondary: {
        main: '#1976d2',
      },
      text: {
        primary: '#B2DBF7',
      }
    },
    overrides: {
      // Style sheet name ⚛️
      MuiPaper: {
        root: {
          backgroundColor: '#333366'
        },

        
      },

      MuiTab: {
        root: {
          minWidth: 0,
         '@media (min-width: 0px)': {
           minWidth: 0
         }
        }
      },
      MuiDialogContent: {
        root: {
          overflowX: 'hidden'
        }
      },
      MuiAppBar: {
        // Name of the rule
        colorPrimary: {
          // Some CSS
          backgroundColor: pink[100],
        },
      },
      MuiSvgIcon: {
        root: {
          marginBottom: -5
        }
      },
      MuiTypography: {
        body1: {
          paddingTop: '0.25em',
          paddingBottom: '0.25em'
        }
      }
    },
  });

  const handleClose = () => {
    // onClose(selectedValue);
  };

  const handleListItemClick = value => {
    // onClose(value);
  };

  return (
    <ThemeProvider theme={theme}>
    <Dialog onClose={handleClose} open={open}>
      <DialogContent>
        <div>
          {/* <img alt="HOT 100 Logo" style={{ width: '100%' }} src={process.env.PUBLIC_URL + '/entries-now-closed.png'} /> */}
          <img alt="HOT 100 Closed" style={{ width: '100%' }} src={process.env.PUBLIC_URL + '/Voting_closed_horizontal.svg'} />
          <img alt="HOT 100 Logo" style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} src={process.env.PUBLIC_URL + '/website.png'} />
          {/* <Typography style={{ fontWeight: 'bold'}}>
               1 Vote per person
          </Typography>

          <Typography>
             <FavoriteBorder /> songs to add them to your shortlist
          </Typography>
          <Typography>
            <AddIcon /> to add tracks not on the songlist
          </Typography>
          <Typography>
            <BookmarkIcon /> to save your shortlist and come back to it later, but only if you are a 4ZZZ subscriber! Not a subscriber? <a href="//4zzz.org.au/subscribe" target="_blank" rel="noopener noreferrer">Fix that!</a>
          </Typography>
          <Typography>
            <HelpOutlineIcon /> for more instructions
          </Typography> */}
					<Typography style={{ fontWeight: 'bold'}}>
               Voting is now closed, listen live on New Years Day to hear all your favourite songs of the year, or come see it all go down and help us celebrate from Midday at the Hot 100 Hangout at the Triffid.
          </Typography>

        </div>
      </DialogContent>
      {/* <DialogActions>
        <Tab style={{ color: theme.palette.secondary[700]}} onClick={() => handleListItemClick('close')} color="secondary" label="Close" icon={<Clear />} aria-label="person" />
      </DialogActions> */}

    </Dialog>
    </ThemeProvider>
  );
}

export default IntroDialog
