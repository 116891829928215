import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";

const HeaderLite = (props) => (
  <AppBar position="relative">
    <Link to="/">
    <Toolbar>
    <img alt="4ZZZ HOT 100" style={{ height: '48px', width: 'auto', paddingRight: '10px', lineHeight: '48px' }} src={process.env.PUBLIC_URL + '/hot100withfriend.png'} />
      <Typography style={{flexGrow: 1, textAlign: 'center', color: 'white', fontWeight: 700 }} variant="h6" color="inherit" noWrap>
        🩵 🩵 🩵 🩵 🩵 🩵 🩵 🩵 🩵
      </Typography>
      <img alt="Heart" style={{ height: 'auto', width: '48px', paddingLeft: '10px', lineHeight: '48px' }} src={process.env.PUBLIC_URL + '/Heartleft.png'} />
    </Toolbar>
    </Link>

  </AppBar>
)

export default HeaderLite
